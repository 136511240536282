html {
  min-height: 100vh;
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  color: $base-color;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  -webkit-font-smoothing: antialiased;

  -webkit-text-size-adjust: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p,
fieldset {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

ol,
ul {
  list-style: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  outline: none;
  cursor: pointer;
}

button:disabled,
html [type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled {
  cursor: not-allowed;
  pointer-events: all;
}

select {
  background-color: white;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
