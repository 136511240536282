// Variables
$base-font-size: 16;
$base-font-family: "AMX", "Trebuchet MS", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-weight: 700;
$base-color: #fff;
$base-background-color: #bb1722;

$breakpoints: (
  mobile: (max-width: 767px),
  tablet: (max-width: 1024px),
);

// Functions
@function em($pxval, $base: $base-font-size) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

@function rem($pxval, $base: $base-font-size) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

// Mixins
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: em(strip-unit($min-value));
  }

  @media (min-width: em(strip-unit($min-vw))) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value}
        + #{strip-unit($max-value - $min-value)}
        * (100vw - #{$min-vw})
          / #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media (min-width: em(strip-unit($max-vw))) {
    @each $property in $properties {
      #{$property}: em(strip-unit($max-value));
    }
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error "No value found for `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
