@font-face {
  font-family: 'AMX';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/AMX/AMX-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
